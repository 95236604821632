.backdrop1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #555555;
    z-index: 5;
}

.bms-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 10px;
    overflow-y: auto;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100px;
}
/* styles.css */


/* Style the Calculator app */

.calculator {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


/* Style the app icon (if you have one) */

.calculator-icon {
    width: 64px;
    height: 64px;
    background-color: #3498db;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
}
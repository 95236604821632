/* src/components/Smartphone.css */

.smartphone {
    width: 370px;
    height: 640px;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    margin: 20px auto;
    overflow: hidden;
    position: relative;
}

.app-container {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}

.apps-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding: 20px;
}
.tabheader {
    display: flex;
    flex-direction: row;
    list-style: none;
    color: blue;
    justify-content: flex-start;
    overflow: auto;
}

.menuitem {
    padding: 5px;
    background-color: aquamarine;
    margin-right: 2px;
}

.activemenu {
    background-color: blueviolet;
}

.contentarea {
    background-color: aqua;
    height: 300px;
}
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftarea {
    width: 200px;
    display: flex;
    background-color: aqua;
    align-items: center;
    padding-left: 20px;
}

.rightarea {
    background-color: aquamarine;
}

.rightbuttons {
    width: 700px;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-evenly;
}

.mypic {
    width: 50px;
    height: 50px;
}

#idsearch {
    font-size: large;
    padding: 10px;
}

.btndefault {
    font-size: large;
    padding: 10px;
    border-radius: 5px;
}

.btnbgnone {
    background-color: white;
}

.btnbgyellow {
    background-color: yellow;
}

.btndefault:hover {
    opacity: 0.7;
}

#btnlogin {
    border: none;
}

#btnregpro {
    border: none;
}

#btnfreereg {
    color: blue;
}